import React from "react";

const AboutUs = () => {
    return (
        <div className="about-us-section shadow mt-5">
            <div className="container">
                <div className="about ">
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className="text-center mb-4">About us </h1>
                            <p>
                                <span className="mt-3"> Welcome to Snow Day Calculator!<br /></span>
                                At Snow Day Calculator, we're passionate about helping you prepare for and embrace the magic of snow days. With the help of our team of data analysts, we provide you with accurate and up-to-date winter weather information. Our proprietary algorithm predicts snow days based on several factors, including temperature, precipitation, and wind speed.<br />
                                Our website offers a user-friendly experience for all, whether you're a student, parent, teacher, or snow day enthusiast. We're dedicated to providing you with reliable and comprehensive weather information so you can confidently plan your day.<br />
                                You always get excited when your school announces a snow day, and we would be grateful if you could get the most out of it. Our website provides various tools and resources, including tips for building snowmen, recommendations for winter activities, and ideas for cozy indoor fun. We're not just a weather prediction service - we're a community of snow day lovers who believe every day should be a snow day.<br />
                                Thank you for choosing Snow Day Calculator as your go-to resource for winter weather. Stay warm, stay safe, and enjoy the snow!<br />
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="about-img">
                                <img className="about-image" src="https://as2.ftcdn.net/v2/jpg/01/02/77/05/1000_F_102770504_8MLOVKC1GOZn1o93ucD6fHqnkBKm3vN1.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;