import axios from "axios";
import { ErrorMessage, Field, Formik } from "formik";
import React from "react";
import * as yup from "yup"
import { contactUs } from "../environment/environment";

const ContactUs = () => {
    const initialValues = {
        email: "",
        message: ""
    };

    const schema = yup.object().shape({
        email: yup.string().required("Required Field"),
        message: yup.string().required("Required Field")
    });
    return (
        <div className="container mt-5">
            <div className="section">
                <div className="contact-us">
                    <div className="row">
                        <div className="col-md-10  offset-md-1">
                            <div className="contact_inner">
                                <div className="row">
                                    <div className="col-ms-10">
                                        <div className="contact_form_inner">


                                            <div className="contact_field">
                                                <div className="ms-5 me-5 mt-5 mb-5">

                                                    <h1 className="text-dark">Contact Us</h1>
                                                    <p className="text-dark">Please feel free to reach out to us with any questions, comments, or feedback.</p>
                                                    <Formik
                                                        initialValues={initialValues}
                                                        validationSchema={schema}
                                                        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                                                            const data = {
                                                                email: values.email,
                                                                message: values.message
                                                            }
                                                            axios.post(contactUs, data)
                                                                .then((res) => console.log(res))
                                                                .catch((err) => console.log(err))
                                                        }}
                                                    >
                                                        {(formik) => {
                                                            const {
                                                                values,
                                                                isSubmitting,
                                                                handleSubmit,
                                                                handleChange,
                                                            } = formik;
                                                            return (
                                                                <div className="">
                                                                    <form onSubmit={handleSubmit}>
                                                                        <div className="row">

                                                                            <div className="mb-3 position-relative">
                                                                                <label
                                                                                    htmlFor="name"
                                                                                    className="form-control-label"
                                                                                >
                                                                                    Email
                                                                                </label>
                                                                                <Field
                                                                                    type="email"
                                                                                    name="email"
                                                                                    id="email"
                                                                                    className="form-control"
                                                                                    placeholder="Enter your email"
                                                                                    class="form-control form-group"
                                                                                />
                                                                                <ErrorMessage
                                                                                    name="email"
                                                                                    component="span"
                                                                                    className="error"
                                                                                />
                                                                            </div>


                                                                            <div className="mb-3 position-relative">
                                                                                <label
                                                                                    htmlFor="name"
                                                                                    className="form-control-label"
                                                                                >
                                                                                    Message
                                                                                </label>
                                                                                <textarea
                                                                                    name="message"
                                                                                    rows="3"
                                                                                    className="form-control"
                                                                                    placeholder="Enter your message"
                                                                                    value={values.message}
                                                                                    onChange={handleChange}
                                                                                />
                                                                                <ErrorMessage
                                                                                    name="message"
                                                                                    component="span"
                                                                                    className="error"
                                                                                />
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <button
                                                                                        className="btn btn-primary min-w150 rounded-pill mt-3"
                                                                                        class="contact_form_submit"
                                                                                        type="submit" disabled={isSubmitting}>
                                                                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </form>
                                                                </div>
                                                            );
                                                        }}
                                                    </Formik>

                                                </div >
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 contact_info_sec me-3">
                            <div className="info-heading">
                                <h2>Contact Information</h2>
                                <div class="d-flex info_single align-items-center">
                                    <p>
                                        <i class="fas fa-headset"></i> <span>Phone: +(92) 342-4443385</span>
                                    </p>
                                </div>

                            </div>
                            <div className="d-flex info_single align-items-center">
                                <p>
                                    <i class="fas fa-envelope-open-text"></i> <span>Email: snowdaycalculator123@gmail.com</span>
                                </p>
                            </div>
                            {/* <div className="d-flex info_single align-items-center">
                    <p>
                    <i class="fas fa-map-marked-alt"></i> <span>Address: 1234 Main Street, Suite 567, City, State ZIP Code</span> 
                    </p>
                    </div> */}

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ContactUs;
