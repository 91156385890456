import logoicon from "../assets/images/snowday.png";
function Footer() {
  return (
    <footer className="text-center text-lg-start footer mt-2">
      <div class="container">
        <div class="row">
          <div class="footer-col">
            <div class="footerlogo">
              <img
                src={logoicon}
                className="rounded-2"
                alt=" Snow Day Calculator"
              />
              <h3>Snow Day Calculator</h3>
            </div>
          </div>
          <div class="footer-col">
            <div className="d-flex">
              <ul className="me-4">
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Disclaimer</a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="#">Blog</a>
                </li>
                <li>
                  <a href="#">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="footer-col">
            {/* <h4>follow us</h4> */}
            <div class="social-links">
              <a href="#">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>

              <a href="#">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>

          <div class="footer-col">
            <div className="text-center footerbotom">
              © 2023 Snow Day Calculator. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>

      {/*
        <div className="text-center p-3 footerbotom">
          © 2023 My Weather App. All Rights Reserved.
        </div> */}
    </footer>
  );
}

export default Footer;
