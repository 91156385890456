import React from "react";
import pantryimg from "../assets/images/pantry.webp";
import bottleimg from "../assets/images/buybottle.webp";
import hygieneimg from "../assets/images/hygiene.webp";
import emergencyimg from "../assets/images/emergency.webp";
import stockimg from "../assets/images/stock.webp";
import checkoutimg from "../assets/images/checkout.webp";
import communicationimg from "../assets/images/communication.webp";
import pipesimg from "../assets/images/pipes.webp";
import windowimg from "../assets/images/window.webp";
import smokeimg from "../assets/images/smoke.webp";
import essentialimg from "../assets/images/essential.webp";
import winterimg from "../assets/images/winter.webp";
import fortimg from "../assets/images/fort.webp";
import snowmanimg from "../assets/images/snowman.webp";
import movieimg from "../assets/images/movie.webp";
import snowballimg from "../assets/images/snowball.webp";
import playgamesimg from "../assets/images/playgames.webp";
import friendimg from "../assets/images/friend.webp";
import sladdingimg from "../assets/images/sladding.webp";
import readingimg from "../assets/images/reading.webp";
import blanketimg from "../assets/images/blanket.webp";
import huntimg from "../assets/images/hunt.webp";
import tomatoimg from "../assets/images/tomato.webp";
import winterslapimg from "../assets/images/winterslap.webp";
import cocoaimg from "../assets/images/cocoa.webp";
import sweattreatimg from "../assets/images/sweattreat.webp";
import chickenimg from "../assets/images/chicken.webp";
import exoticimg from "../assets/images/exotict.webp";
import breadimg from "../assets/images/bread.webp";
import eastAsianimg from "../assets/images/eastAsian.webp";
import potatoimg from "../assets/images/potato.webp";
import Tipsimg from "../assets/images/Tips.webp";
import landscapeimg from "../assets/images/landscape.webp";
import shipimg from "../assets/images/ship.webp";
import globeimg from "../assets/images/globe.webp";
import wonderlandimg from "../assets/images/woonderland.webp";
import magneticimg from "../assets/images/magnetic.webp";
import snowoasisimg from "../assets/images/snowoasis.webp";
import historybridgeimg from "../assets/images/historybridge.webp";
import snowshoresimg from "../assets/images/snowshores.webp";
import magicalimg from "../assets/images/magical.webp";
import skylineimg from "../assets/images/skyline.webp";
import saultimg from "../assets/images/sault.webp";
import Syracuseimg from "../assets/images/Syracuse.webp";
import Juneauimg from "../assets/images/Juneau.webp";
import Duluthimg from "../assets/images/Duluth.webp";
import Erieimg from "../assets/images/Erie.webp";
import chrismsimg from "../assets/images/chrisms.webp";
import Muskegonimg from "../assets/images/Muskegon.webp";
import Casperimg from "../assets/images/Casper.webp";
import portlandimg from "../assets/images/Portland.webp";
import Worcesterimg from "../assets/images/Worcester.webp";

const Detail = () => {
  return (
    <div className="half-img-half-text">
      <div className="container">
        <div className="blog-Article_detail">
          <div className="row">
            <div className="col-12">
              <div className="cardbox">
                <h1 className="my-5">
                  10 Crucial Steps for Successful Winter Storm Readiness
                </h1>
                <div className="imgtext">
                  <div className="img">
                    <img
                      className=" essential-img w-100"
                      src={essentialimg}
                      alt=""
                    />
                  </div>

                  <p className="mb-4 ">
                    In the throes of winter, storms can be relentless and
                    unpredictable. One minute you're sitting by the fire with a
                    good book, the next you're plunged into darkness with the
                    power out and a howling gale outside. When a storm's on the
                    horizon, the secret to sailing through without a scratch is
                    all about getting ready ahead of time. With the right
                    preparation, you can make sure that you and the folks you
                    care about are tucked in safe and sound, no matter what
                    curveball the weather decides to throw your way. In this
                    comprehensive guide, we share ten crucial steps to take for
                    optimal winter storm preparedness.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="foodpantry subcard">
            <div className="row">
              <div className="col-12">
                <div className="widow-door">
                  <h2 className="mt-3 mb-3">1. Stock Your Food Pantry</h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img img"
                    src={pantryimg}
                    alt="Preserved food On shelves"
                  />
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="content">
                  <p className="food-text">
                    One of the first steps in your winter storm preparation
                    should be to ensure you have an adequate food supply.
                    Unpredictable road conditions may make trips to the grocery
                    store difficult, if not impossible. It's good to have food
                    that won't spoil ready to eat. Think along the lines of
                    canned goods, rice, oatmeal, granola bars, and long-lasting
                    fruits such as apples, oranges, and bananas.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className="horizontal-break mt-4 " />

          {/* buy bottle */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="buy-bottle">
                  <h2 className="mt-3 mb-3">2. Buy Bottled Water</h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={bottleimg}
                    alt="Bottled mineral water"
                  />
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <p className="">
                  An often overlooked aspect of winter storm preparation is the
                  need for an ample supply of water. When it gets really cold
                  and goes below freezing, your pipes might freeze. This can
                  mess up the water in your house. The National Oceanic and
                  Atmospheric Administration (NOAA) recommends a provision of at
                  least a gallon of water per person per day for three days.
                  Also, remember to factor in the water needs of your pets.
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />

          {/* Gather Hygiene  */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="Gather-hygiene">
                  <h2 className="mt-3 mb-3">
                    3. Fill Your Prescriptions and Gather Hygiene Items
                  </h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={hygieneimg}
                    alt="Prescriptions with hygiene items to collect"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  Remember to take care of your health during a winter storm.
                  Make sure you have enough of your needed medicines to last
                  until the storm's over. Also, have enough things like toilet
                  paper, soap, wet wipes, and women's care products. You don't
                  want to run out of these!
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />

          {/* emergencykit */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="emergency-kit">
                  <h2 className="mt-3 mb-3">4. Build An Emergency Kit</h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={emergencyimg}
                    alt="Emergency First Aid Kit"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  If a winter storm comes, having an emergency kit could save
                  your life. In the kit, put important things like a first-aid
                  kit, flashlight, batteries,{" "}
                  <a
                    href="https://www.weather.gov/nwr&ln_desc=NOAA+Weather+Radio/"
                    target="_blank"
                  >
                    {" "}
                    a weather radio{" "}
                  </a>
                  , phone chargers, money, and other must-haves. If you've got
                  little kids, remember to put in things like diapers too.
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />
          {/* stockupice */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="stockup">
                  <h2 className="mt-3 mb-3">
                    5. Stock Up on Ice Melt and Check Your Tools
                  </h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={stockimg}
                    alt="Man on Stock up ice with a tool in his hand "
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  Having a bag of ice melt, sand, or kitty litter can make all
                  the difference when it comes to navigating slippery walkways
                  around your home. In addition to these, check your winter
                  tools such as snow shovels and ice scrapers to ensure they're
                  in good working condition.
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />

          {/* powerblock */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="powerblack">
                  <h2 className="mb-3">6. Prepare For A Power Blackout</h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={checkoutimg}
                    alt="Indication of power balckout"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  Winter storms often bring about power blackouts. Prepare for
                  this by having plenty of candles, matches, batteries for
                  flashlights, and a backup power source such as a generator.
                  It's also prudent to ensure your generator is functioning
                  correctly and that you have enough fuel to power it.
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />
          {/* communication plan */}
          <div className="subcard">
            <div className="row">
              <div className="col-md-12">
                <div className="communication-plan">
                  <h2 className="mt-3 mb-3">
                    7. Create An Emergency Communication Plan
                  </h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={communicationimg}
                    alt="Family and house drawing craft indicating communication plan"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  In the heat of a winter storm, communication can be a
                  lifeline. Establish an emergency communication plan with your
                  family. It's crucial that each member of the family
                  understands what to do, who to contact, and where to meet in
                  case you get separated during the storm.
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />

          {/* waterpipes */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="water-pipes">
                  <h2 className="mt-3 mb-3">8. Protect Your Water Pipes</h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={pipesimg}
                    alt="Fixing water pipes "
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  If pipes freeze, they can cause a lot of damage and fixing
                  them can cost a lot of money. Insulate your pipes using pipe
                  insulation or heat tape to prevent freezing. For extreme cold,
                  consider allowing your faucets to drip slightly to keep water
                  flowing.
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />

          {/* windowdoor */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="widow-door">
                  <h2 className="mt-3 mb-3">9. Seal Your Windows and Doors</h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={windowimg}
                    alt="Closed windows and doors on a snow day "
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  A well-sealed home retains heat much more efficiently. By
                  using window insulation kits or weatherstripping, you can seal
                  any cracks or gaps in your doors and windows to keep out the
                  cold and save on heating costs
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />
          {/* smoke */}
          <div className="subcard">
            <div className="row">
              <div className="col-12">
                <div className="smoke">
                  <h2 className="mt-3 mb-3">
                    10. Check or Install New Smoke and Carbon Monoxide Detectors
                  </h2>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="img">
                  <img
                    className="blog-img"
                    src={smokeimg}
                    alt="Carbon monoxide detector installed on the wall "
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <p className="">
                  Your home's safety mechanisms are just as critical during a
                  winter storm. Make sure your smoke and carbon monoxide
                  detectors are working right. If they need new batteries, be
                  sure to put them in. If you don't have these detectors,
                  consider installing them before the storm hits.
                </p>
              </div>
            </div>
          </div>
          <hr className="horizontal-break mt-4 " />
        </div>
      </div>
      <div className="conclusion mt-4">
        <div className="container">
          <h2 className="mt-2 mb-2">Conclusion</h2>
          <p className="mb-2">
            In the face of winter storms, being prepared is half the battle. By
            following these steps, you can ensure your household weathers the
            storm safely and comfortably. Remember, the key to effective winter
            storm preparedness is acting early – start your preparations now
            with our{" "}
            <a href="\" className="internallink">
              Snow day calculator
            </a>{" "}
            and have peace of mind when the storm hits!
          </p>
        </div>
      </div>
      <section>
        <div className="container">
          <div className="faQ">
            <div className="row">
              <div className="col-md-3">
                <img
                  className="FaQ-img mt-3"
                  src="https://img.freepik.com/free-vector/faqs-concept-illustration_114360-5245.jpg?w=996&t=st=1687608078~exp=1687608678~hmac=bebd02dedf5018ca6f9d327e6403782c9e4c10ef793d1fcdef1f5684f44d2506"
                  alt=""
                />
              </div>
              <div className="col-md-9">
                <div className="FaQAnswer mt-3">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="faq">
                        <div className="faq-item">
                          <input class="faq-input" type="checkbox" id="faq_1" />
                          <label class="faq-title" for="faq_1">
                            <h4>
                              How can I ensure my pet's safety during a winter
                              storm?
                            </h4>
                          </label>
                          <div className="faq-text">
                            <p>
                              Keep your pets indoors as much as possible. If
                              they need to go outside, make sure it's for a
                              brief period. Wipe their paws when they come in.
                              Keep enough pet food and water on hand to last the
                              duration of the storm.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq">
                        <div className="faq-item">
                          <input class="faq-input" type="checkbox" id="faq_2" />
                          <label class="faq-title" for="faq_2">
                            <h4>
                              What precautions should I take if I need to drive
                              during a winter storm?
                            </h4>
                          </label>
                          <div className="faq-text">
                            <p>
                              If possible, avoid driving during a winter storm.
                              If you must drive, ensure your car has an
                              emergency kit, which includes items such as a snow
                              shovel, windshield scraper, flashlight, water,
                              snack food, and a blanket.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="faq">
                        <div className="faq-item">
                          <input class="faq-input" type="checkbox" id="faq_3" />
                          <label class="faq-title" for="faq_3">
                            <h4>
                              What types of food should I stock for a winter
                              storm?
                            </h4>
                          </label>
                          <div className="faq-text">
                            <p>
                              Stock up on non-perishable items such as canned
                              goods, peanut butter, granola bars, and dry
                              cereal. Consider foods that don't require cooking,
                              as you may be without power.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="faq">
                        <div className="faq-item">
                          <input class="faq-input" type="checkbox" id="faq_4" />
                          <label class="faq-title" for="faq_4">
                            <h4>How can I stay updated about the storm?</h4>
                          </label>
                          <div className="faq-text">
                            <p>
                              Keep a battery-powered or hand-crank radio to
                              listen to news updates. Weather apps on your
                              mobile phone can also keep you updated about the
                              storm's progress.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Detail;
