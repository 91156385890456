import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoicon from "../assets/images/snowday.png";
const Header = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("home");

  useEffect(() => {
    if (window.location.pathname === "/") setSelectedMenuItem("home");
    else if (window.location.pathname === "/contact-us")
      setSelectedMenuItem("contact");
    else if (window.location.pathname === "/privacy-policy")
      setSelectedMenuItem("privacy");
    else if (window.location.pathname === "/articles")
      setSelectedMenuItem("disclaimer");
    else if (window.location.pathname === "/about-us")
      setSelectedMenuItem("about");
  }, []);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
  };

  return (
    <header class="header-style mt-1 sticky-top">
      <nav className="navbar navbar-expand-lg">
        <Link
          className="navbar-brand ms-3 text-white"
          to="/"
          onClick={() => handleMenuItemClick("Home")}
        >
          <img
            src={logoicon}
            className="rounded-2"
            alt=" Snow Day Calculator"
          />
          Snow Day Calculator
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon">
            <i class="fa-solid fa-bars"></i>
          </span>
        </button>

        <ul
          className="header-section pe-4 collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <li className={selectedMenuItem === "home" ? "selected" : ""}>
            <Link to="/" onClick={() => handleMenuItemClick("home")}>
              <i class="icons text-white bi bi-house"></i>
              <h6 className="ms-4">Home</h6>
            </Link>
          </li>
          <li className={selectedMenuItem === "contact" ? "selected" : ""}>
            <Link
              to="/contact-us"
              onClick={() => handleMenuItemClick("contact")}
            >
              <i class="icons bi bi-person text-white me-3"></i>
              <h6 className="ms-4">Contact Us</h6>
            </Link>
          </li>
          <li className={selectedMenuItem === "disclaimer" ? "selected" : ""}>
            <Link
              to="/disclaimer"
              onClick={() => handleMenuItemClick("disclaimer")}
            >
              <i class="icons bi bi-disc text-white"></i>
              <h6 className="ms-4">Disclaimer</h6>
            </Link>
          </li>
          <li className={selectedMenuItem === "privacy" ? "selected" : ""}>
            <Link
              to="/privacy-policy"
              onClick={() => handleMenuItemClick("privacy")}
            >
              <i class="icons bi bi-shield-check text-white"></i>
              <h6 class="ms-4">Privacy Policy</h6>
            </Link>
          </li>
          <li className={selectedMenuItem === "blog" ? "selected" : ""}>
            <Link to="/blog" onClick={() => handleMenuItemClick("blog")}>
              <i class="icons bi bi-globe text-white"></i>
              <h6 className="ms-4"> Blog</h6>
            </Link>
          </li>
          <li className={selectedMenuItem === "about" ? "selected" : ""}>
            <Link to="/about-us" onClick={() => handleMenuItemClick("about")}>
              <i class="icons bi bi-person text-white"></i>
              <h6 className="ms-4"> About Us</h6>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
