import React from "react";

import essentialimg from "../assets/images/essential.webp";
import buybottleimg from "../assets/images/buybottle.webp";
import checklistwimg from "../assets/images/checklistw.webp";
import cozyimg from "../assets/images/cozy.webp";
import activityimg from "../assets/images/activity.webp";
import Tipsimg from "../assets/images/Tips.webp";
import canadaimg from "../assets/images/canada.webp";
import Topusimg from "../assets/images/Topus10.webp";
import winterimg from "../assets/images/winter.webp";

const Blogs = () => {
  return (
    <div className="blog-Article blogcard">
      <div className="container">
        <h3 className="Blogs-heading mt-5 mb-3">Blogs</h3>{" "}
        <hr className="pb-5" />
        <div class="cards-wrapper">
          <a href="/snow-day-activities-kids-home" class="card">
            <div class="image-wrapper">
              <img
                src={activityimg}
                className="rounded-2"
                alt="Indication of power balckout"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Snow Day Activities for Kids at home</h5>
              <p class="card-text">
                When the snow is falling, schools are closed, and the day
                stretches out ahead, finding activities to keep your kids..
              </p>
              <a href="/snow-day-activities-kids-home" class="btn  my-3">
                Details
              </a>
            </div>
          </a>
          <a href="/snow-days-delays-tips-planning-parents" class="card">
            <div class="image-wrapper">
              <img
                src={Tipsimg}
                className="rounded-2"
                alt="A snowman with a carrot nose, wearing a top hat and a green scarf, with black buttons made of stones down its front"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                Snow days and delays: tips and better planning for parents
              </h5>
              <p class="card-text">
                Ah, the bliss and bedlam of snow days! A winter wonderland for
                kids but sometimes a logistical nightmare for parents. How do
                you keep the little ones entertained, educated, and most
                importantly, safe? Fret not, we’ve got you covered like a thick
                blanket on a chilly morning.
              </p>
              <a href="/snow-days-delays-tips-planning-parents" class="btn my-3">
                Details
              </a>
            </div>
          </a>
          <a href="/top-snowiest-cities-canada" class="card">
            <div class="image-wrapper">
              <img
                className="rounded-2"
                src={canadaimg}
                alt="A variety of movie snacks on a table, including popcorn, spicy chips, and drinks on a snow day"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                Embrace the Chill: Top 10 Snowiest Cities in Canada for 2023{" "}
              </h5>
              <p class="card-text">
                In 2023, as the temperature dips and flakes start to fall,
                Canada transforms into a snowy paradise.{" "}
              </p>
              <a href="/top-snowiest-cities-canada" class="btn my-3">
                Details
              </a>
            </div>
          </a>
          <a href="/essential-steps-prepare-winter-storm" class="card ">
            <div class="image-wrapper">
              <img
                className="rounded-2"
                src={essentialimg}
                alt="A steaming, slow-cooked beef bowl stew with tender beef chunks."
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                10 Crucial Steps for Successful Winter Storm Readiness{" "}
              </h5>
              <p class="card-text">
                In the throes of winter, storms can be relentless and
                unpredictable. One minute you're sitting by the fire with a good
                book,{" "}
              </p>
              <a href="/essential-steps-prepare-winter-storm" class="btn my-3">
                Details
              </a>
            </div>
          </a>
          <a href="/winter-storm-preparedness-checklist" class="card">
            <div class="image-wrapper">
              <img
                className="rounded-2"
                src={checklistwimg}
                alt="Deliciously tender Braised Oxtails with Carrots and Chiles, a flavorful and spicy dish"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">
                Essential Checklist Guide to Winter Storm Readiness{" "}
              </h5>
              <p class="card-text">
                Winter is really special. Everything's covered in white, icicles
                sparkle, and playing in the snow is a lot of fun. But winter can
                also be tough with blizzards, icy storms, and very cold weather.
                Being ready for all this can make winter nice and cozy instead
                of dangerous. Our big list of things to do to get ready for
                winter storms will help you, your family, and your home be
                prepared for anything winter might bring.
              </p>
              <a href="/winter-storm-preparedness-checklist" class="btn my-3">
                Details
              </a>
            </div>
          </a>
          <a href="/cozy-recipes-snow-day" class="card">
            <div class="image-wrapper">
              <img
                src={cozyimg}
                className="rounded-2"
                alt="Moncton’s famous mystery magnetic hill park"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">Cozy Recipes to Make on a Snow Day</h5>
              <p class="card-text">
                When the snowflakes start to fall, and the outside world turns
                into a winter wonderland,there's nothing quite like hunkering{" "}
              </p>
              <a href="/cozy-recipes-snow-day" class="btn my-3">
                Details
              </a>
            </div>
          </a>
          <a href="/top-snowiest-cities-us" class="card">
            <div class="image-wrapper">
              <img
                src={Topusimg}
                className="rounded-2"
                alt="child is seated on a sled wearing warm clothes, gliding down a snow hill enjoying a snow day"
              />{" "}
            </div>
            <div class="card-body">
              <h5 class="card-title">
                The Ultimate Guide to the Top 10 U.S Snowiest Cities in 2023
              </h5>
              <p class="card-text">
                Snowfall paints a beautiful picture of nature. For some, it
                means cozying up by the fire with a good book,
              </p>
              <a href="/top-snowiest-cities-us" class="btn my-3">
                Details
              </a>
            </div>
          </a>
          <a href="/dress-winter-weather-guide" class="card">
            <div class="image-wrapper">
              <img
                src={winterimg}
                className="rounded-2"
                alt="Family and house drawing craft indicating communication plan"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">
              How to Dress for Winter Weather: Your Complete Handbook

              </h5>
              <p class="card-text">
                As the days grow shorter and the temperatures plummet, dressing
                appropriately becomes more of an art than a simple routine. This
                guide will help you pick your winter clothes, put them on in
                layers to stay warm, stop from getting too hot, and know if
                someone's too cold from hypothermia. It's got everything you
                need!
              </p>
              <a href="/dress-winter-weather-guide" class="btn  my-3">
                Details
              </a>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
