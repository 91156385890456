import React from "react";
import pantryimg from "../assets/images/pantry.webp";
import bottleimg from "../assets/images/buybottle.webp";
import hygieneimg from "../assets/images/hygiene.webp";
import emergencyimg from "../assets/images/emergency.webp";
import stockimg from "../assets/images/stock.webp";
import checkoutimg from "../assets/images/checkout.webp";
import communicationimg from "../assets/images/communication.webp";
import pipesimg from "../assets/images/pipes.webp";
import windowimg from "../assets/images/window.webp";
import smokeimg from "../assets/images/smoke.webp";
import essentialimg from "../assets/images/essential.webp";
import winterimg from "../assets/images/winter.webp";
import fortimg from "../assets/images/fort.webp";
import snowmanimg from "../assets/images/snowman.webp";
import movieimg from "../assets/images/movie.webp";
import snowballimg from "../assets/images/snowball.webp";
import playgamesimg from "../assets/images/playgames.webp";
import friendimg from "../assets/images/friend.webp";
import sladdingimg from "../assets/images/sladding.webp";
import readingimg from "../assets/images/reading.webp";
import blanketimg from "../assets/images/blanket.webp";
import huntimg from "../assets/images/hunt.webp";
import tomatoimg from "../assets/images/tomato.webp";
import winterslapimg from "../assets/images/winterslap.webp";
import cocoaimg from "../assets/images/cocoa.webp";
import sweattreatimg from "../assets/images/sweattreat.webp";
import chickenimg from "../assets/images/chicken.webp";
import exoticimg from "../assets/images/exotict.webp";
import breadimg from "../assets/images/bread.webp";
import eastAsianimg from "../assets/images/eastAsian.webp";
import potatoimg from "../assets/images/potato.webp";
import Tipsimg from "../assets/images/Tips.webp";
import landscapeimg from "../assets/images/landscape.webp";
import shipimg from "../assets/images/ship.webp";
import globeimg from "../assets/images/globe.webp";
import wonderlandimg from "../assets/images/woonderland.webp";
import magneticimg from "../assets/images/magnetic.webp";
import snowoasisimg from "../assets/images/snowoasis.webp";
import historybridgeimg from "../assets/images/historybridge.webp";
import snowshoresimg from "../assets/images/snowshores.webp";
import magicalimg from "../assets/images/magical.webp";
import skylineimg from "../assets/images/skyline.webp";
import saultimg from "../assets/images/sault.webp";
import Syracuseimg from "../assets/images/Syracuse.webp";
import Juneauimg from "../assets/images/Juneau.webp";
import Duluthimg from "../assets/images/Duluth.webp";
import Erieimg from "../assets/images/Erie.webp";
import chrismsimg from "../assets/images/chrisms.webp";
import Muskegonimg from "../assets/images/Muskegon.webp";
import Casperimg from "../assets/images/Casper.webp";
import portlandimg from "../assets/images/Portland.webp";
import Worcesterimg from "../assets/images/Worcester.webp";

const BuyBottle = () => {
  return (
    <div className="container">
      Buy Bottle
    </div>
  );
};

export default BuyBottle;
