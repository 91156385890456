    import axios from "axios";
    import { Formik, Field, ErrorMessage } from "formik";
    import { useState } from "react";
    import { getWeather } from "../environment/environment";
    import * as yup from "yup";
    import GaugeChart from "react-gauge-chart";

    function SnowPredictor(props) {
        const [weather, setWeather] = useState()
        const [isEnable, setIsEnable] = useState(false)
        const initialValues = {
            countryCode: "us",
            areaCode: ""
        };

        const schema = yup.object().shape({
            areaCode: yup.string().required("Required Field")
        });

        return (
            <>
                <div className="ms-5 me-5 mt-5 mb-5">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                            let data = {};
                            const { countryCode, areaCode } = values;
                            if (countryCode == 'us')
                                data["zipcode"] = areaCode
                            else
                                data["postalcode"] = areaCode

                            axios.post(getWeather, data)
                                .then((res) => {
                                    setWeather(res?.data)
                                    setIsEnable(true)
                                })
                                .catch(err => console.log(err))
                        }}
                    >
                        {(formik) => {
                            const {
                                values,
                                handleSubmit,
                                isSubmitting
                            } = formik;
                            return (
                                <div className="forms">
                                    <form onSubmit={handleSubmit}>
                                        <div className="">
                                            <div className=" form-sec">
                                                <label
                                                    htmlFor="name"
                                                    className="form-control-label"

                                                >
                                                    Country
                                                </label>

                                                <Field as="select" name="countryCode" className="field-select mt-2 from-select">
                                                    <option defaultValue='us' value="us">US</option>
                                                    <option value="ca">CA</option>
                                                </Field>

                                            </div>
                                            {values.countryCode === "us" && (
                                                <div className="mt-3">
                                                    <div className="mb-3 position-relative">
                                                        <label
                                                            htmlFor="name"
                                                            className="form-control-label mb-2"
                                                        >
                                                            Zip Code
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="areaCode"
                                                            id="areaCode"
                                                            className="form-control"
                                                            placeholder="Enter Your Zip Code"
                                                            class="zip-input"
                                                        />
                                                        <ErrorMessage
                                                            name="areaCode"
                                                            component="span"
                                                            className="error"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {values.countryCode === "ca" && (
                                                <div className="mt-3">
                                                    <div className="mb-3 position-relative">
                                                        <label
                                                            htmlFor="name"
                                                            className="form-control-label mb-2"
                                                        >
                                                            Postal Code
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            name="areaCode"
                                                            id="areaCode"
                                                            className="form-control"
                                                            placeholder="Enter Your Postal Code"
                                                            class="zip-input ps-2"
                                                        />
                                                        <ErrorMessage
                                                            name="areaCode"
                                                            component="span"
                                                            className="error"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-12 ms-auto">
                                                <button
                                                    className="submit-button min-w150 rounded-pill  text-white ps-2 pe-2"
                                                    type="submit"
                                                    disabled={isSubmitting}>
                                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            );
                        }}
                    </Formik>
                </div >
                {
                    isEnable ?
                            <div className="gauge-chart3">
                        <div className="row">
                            {
                                weather?.status ?
                                    <GaugeChart
                                        id="gauge-chart3"
                                        nrOfLevels={30}
                                        colors={["#87ceeb","#87cefa","#00bfff"]}
                                        hideText="false"
                                        arcWidth={0.3}
                                        percent={(weather?.result) / 100}
                                    /> : null
                            }
                            < span className="col-md-12 mb-3 text-center"> {weather?.message} </span >
                        </div >
                        </div>
                        : null
                }
            </>
        );

    }
    export default SnowPredictor;
