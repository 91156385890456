import { Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import HomePage from "./pages/HomePage";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ContactUs from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivicyPolicies";
import Disclaimer from "./pages/Disclaimer";
import AboutUs from "./pages/AboutUs";
import Blogs from "./pages/Blogs";
import Detail from "./pages/Detail";
import BuyBottle from "./pages/BuyBottle";
import Cozyrecipe from "./pages/Cozyrecipe";
import Emabarcechild from "./pages/Emabarcechild";
import Essentialguide from "./pages/Essentialguide";
import Impactofwinter from "./pages/Impactofwinter";
import Snowdayactivity from "./pages/Snowdayactivity";
import Snowdaydelays from "./pages/Snowdaydelays";
import Ultimateguide  from "./pages/Ultimateguide";


function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/essential-steps-prepare-winter-storm" element={<Detail />} />
        <Route path="/buy-bottle" element={<BuyBottle />} />
        <Route path="/cozy-recipes-snow-day" element={<Cozyrecipe />} />
        <Route path="/top-snowiest-cities-canada" element={<Emabarcechild />} />
        <Route path="/winter-storm-preparedness-checklist" element={<Essentialguide />} />
        <Route path="/dress-winter-weather-guide" element={<Impactofwinter />} />
        <Route path="/snow-day-activities-kids-home" element={<Snowdayactivity />} />
        <Route path="/snow-days-delays-tips-planning-parents" element={<Snowdaydelays />} />
        <Route path="/top-snowiest-cities-us" element={<Ultimateguide />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;